import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import './WebcamCapture.css'; // Import custom CSS for styling

function WebcamCapture() {
  const webcamRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('');
  const [imageUrl, setImageUrl] = useState(''); // State to hold image URL for artistic rendering
  const [mode, setMode] = useState('description'); // State to track the selected mode

  const capture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const base64Image = imageSrc.split(',')[1]; // Remove data:image/jpeg;base64, part

    setLoading(true);
    try {
      // Select the correct API endpoint based on the mode
      const apiUrl = mode === 'artist' 
        ? `${process.env.REACT_APP_BACKEND_URL}/routes/artistic_image` 
        : `${process.env.REACT_APP_BACKEND_URL}/routes/process_image`;

      const res = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ image: base64Image })
      });

      const data = await res.json();
      if (mode === 'description') {
        setResponse(data.message);
        setImageUrl(''); // Clear image URL in description mode
      } else if (mode === 'artist') {

        console.log(data, 'data')
        setImageUrl(data.message); // Set the image URL for artistic rendering
        setResponse(''); // Clear response in artist mode
      }
    } catch (error) {
      console.error('Error:', error);
      setResponse('Error processing the image');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="webcam-container">
      <div className="webcam-wrapper">
        <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
        <div className="buttons-container">
          <button 
            className={mode === 'description' ? 'active' : ''} 
            onClick={() => setMode('description')}
          >
            Description Mode
          </button>
          <button 
            className={mode === 'artist' ? 'active' : ''} 
            onClick={() => setMode('artist')}
          >
            Artistic Mode
          </button>
        </div>
        <button onClick={capture}>Capture Photo</button>
      </div>
      <div className="response-wrapper">
        {loading ? <p>Loading...</p> : (
          <>
            {mode === 'description' && <p className="webcam">{response}</p>}
            {/* {mode === 'artist' && imageUrl && <img src={imageUrl} alt="Artistic Rendering" />} */}
            {mode === 'artist' && imageUrl && (
  <div className="image-container">
       <img src={imageUrl} alt="Artistic Rendering" />
  </div>
)}
          </>
        )}
      </div>
    </div>
  );
}

export default WebcamCapture;
