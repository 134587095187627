import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import e from '../images/e.wav'
import topbarLogo from '../images/ua_wordmark.svg';

import Popup from "./Popup";
import '../styles.css';

const Topbar = ( props ) => {
    const { setMessages, GPT, setGPT, threadID, setThreadID, netID, setNetID, setToken } = props;

    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [popup, setPopup] = useState(false);
    const [popupData, setPopupData] = useState(null);
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let login_url = backend_url + "/routes/login";
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenModel, setDropdownOpenModel] = useState(false);

    const isChatRoute = window.location.pathname === "/chat" || window.location.pathname === "/custom-chat";

    // Handles GPT selection
    const selectGPT = (selectedGPT) => {
        console.log("currentgpt")
        console.log(selectedGPT);

        setGPT(selectedGPT);
        localStorage.setItem('GPT', JSON.stringify(selectedGPT));
        localStorage.removeItem('messages'); // Clear messages from local storage
        localStorage.removeItem('threadID'); // Clear thread from local storage
        setThreadID(null);
        setDropdownOpenModel(!dropdownOpenModel);
    };

    // Opens hamburger menu (mobile only)
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const MenuIcon = () => {
        return menuOpen ? 
            <i className="material-symbols-outlined inline-icon" style={{ color: 'white' }}>close</i> : 
            <i className="material-symbols-outlined inline-icon" style={{ color: 'white' }}>menu</i>;
    };

    // Dropdown menu (mobile only)
    const DropdownMenu = () => {
        return (
            <div className={`dropdown_menu ${menuOpen ? 'show' : ''}`}>
                <button type="button" className="dropdown_button" onClick={() => { toggleMenu(); navigate('/'); }}>Home</button>
                <button type="button" className="dropdown_button" onClick={() => { toggleMenu(); navigate('/portfolio'); }}>Projects</button>
                <button type="button" className="dropdown_button" onClick={() => { toggleMenu(); navigate('/the-prompt'); }}>The Prompt</button>
                <button type="button" className="dropdown_button" onClick={() => { toggleMenu(); navigate('/speaking'); }}>Speaking & Engagement</button>
                {/* <button type="button" className="dropdown_button" onClick={() => { toggleMenu(); navigate('/chat'); }}>Our Chatbot</button> */}
                <button type="button" className="dropdown_button" onClick={() => { toggleMenu(); navigate('/customgpt'); }}>Custom GPTs</button>
                {/* <button type="button" className="dropdown_button" onClick={() => { toggleMenu(); navigate('/webapi-gallery'); }}>WebAPI Gallery</button> */}
                <button type="button" className="dropdown_button" onClick={() => { toggleMenu(); navigate('/wildcat-welcome'); }}>Wildcat Welcome</button>
                <button type="button" className="dropdown_button" style={{ marginBottom: "0px" }} onClick={() => { toggleMenu(); navigate('/work-with-us'); }}>Jobs at AI Core</button>
                <button type="button" className="dropdown_button" style={{ marginBottom: "0px" }} onClick={() => { toggleMenu(); navigate('/webcam'); }}>Webcam GPT</button>
            </div>
        );

    };

    // Clears chatbot history
    const clearHistory = () => {
        localStorage.removeItem('messages'); // Clear messages from local storage
        localStorage.removeItem('threadID'); // Clear thread from local storage
        setThreadID(null);
        setPopup(false);
    };

    // Function to open a popup if needed
    const handlePopup = (title, text, button_text = "", button_function = () => {}) => {
        setPopupData({ title, text, button_text, button_function });
        setPopup(true);
    };

    // Function to toggle the dropdown menu
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const toggleDropdownModel = () => {
        setDropdownOpenModel(!dropdownOpenModel);
    }; function play() {new Audio(e).play()}

    // Topbar dropdown menu
    const UserDropdownMenu = () => {
        return (
            <div className={`user_dropdown_menu ${dropdownOpen ? 'show' : ''}`}>
                <button type="button" className="user_dropdown_button" onClick={() => {navigate('/admin'); setDropdownOpen(false)}}><i className="material-symbols-outlined inline-icon">shield_person</i> Admin Panel</button>
                <button type="button" className="user_dropdown_button" onClick={() => {navigate('/cms'); setDropdownOpen(false)}}><i className="material-symbols-outlined inline-icon">article</i> Content Management</button>
                <button type="button" className="user_dropdown_button" onClick={() => {logout(); setDropdownOpen(false)}}><i className="material-symbols-outlined inline-icon">logout</i> Log Out</button>
            </div>
        );
    };

    // Topbar dropdown menu
    const ModelDropdownMenu = () => {
            return (
                window.location.pathname === "/chat" ? 
            <div className={`model_dropdown_menu ${dropdownOpenModel ? 'show' : ''}`}>
                <button className="pick_model_button" onClick={() => {selectGPT('GPT-4o')}}>
                    <img className="pick_gpt_image" src={require('../images/gpt4.png')} alt=""/>
                    <div className={`pick_model_text ${GPT === "GPT-4o" && "model_active"}`}>GPT-4o</div><br/>
                </button>

                <button className="pick_model_button" onClick={() => {selectGPT('GPT-4o + Chip Bot')}} disabled={false}>
                    <img className="pick_gpt_image" src={require('../images/gpt4.png')} alt=""/>
                    <div className={`pick_model_text ${GPT === "GPT-4o + Chip Bot" && "model_active"}`}>GPT-4o + Chip Bot</div><br/>
                </button>

                <button className="pick_model_button" onClick={() => {selectGPT('Claude + Vault Bot')}} disabled={false}>
                    <img className="pick_gpt_image" src={require('../images/claude.jpg')} alt=""/>
                    <div className={`pick_model_text ${GPT === "Claude + Vault Bot" && "model_active"}`}>Claude + Vault Bot</div><br/>
                </button>

            </div>
            :  window.location.pathname === "/custom-chat" ? 
            <div>
                 <button className="pick_model_button" onClick={() => {
                    // selectGPT('Pokemon AI Assistant');
                    console.log("clicked")}}>
                    <img className="pick_gpt_image" src={require('../images/default.webp')} alt=""/>
                    <div className={`pick_model_text ${ "model_active"}`}>{`${GPT}`}</div><br/>
                </button>
            </div>

            : ''
        );
    };

    // Function to log out
    const logout = () => {
        localStorage.setItem("netID", null)
        localStorage.setItem("token", null)
        setNetID(null);
        setToken(null);
        navigate('/');
    };
    
    return (
        <>
        <Popup popupData={popupData} popup={popup} setPopup={setPopup} GPT={GPT} setGPT={setGPT} setThreadID={setThreadID}/>

        <header className="topbar" style={{ paddingLeft: isChatRoute ? "260px" : "" }}>
            {!isChatRoute && 
            <div className={`red_banner`}>
                <div className="red_banner_container">
                    <a href="https://www.arizona.edu"><img className="topbar_logo" src={topbarLogo} alt="Topbar Logo"/></a>

                    {!isChatRoute && <>
                        <button type="button" className="mobile_menu_button" onClick={toggleMenu}><MenuIcon/></button>
                        {(!netID || netID === "null") ? <a href={login_url}><button className="other_menu_button">Log in <i className="material-symbols-outlined inline-icon" style={{ color: "white" }}>login</i></button></a>
                        : <button className="netid_menu_button" onClick={toggleDropdown}>{netID}</button>}</>}
                </div>
            </div>}

            <DropdownMenu/>
            <UserDropdownMenu/>
            <ModelDropdownMenu/>

            {!isChatRoute ?
            <><div className="white_banner">
                <div className="white_banner_container">
                    <div className="title">AI Cor<span className="title e" onClick={()=> {play()}}>e</span></div>

                    <div className="menu_bar">
                        <button type="button" className={`menu_button ${window.location.pathname === "/" && 'menu_button_selected'}`} onClick={() => {navigate('/')}}>Home</button>
                        <button type="button" className={`menu_button ${window.location.pathname === "/portfolio" && 'menu_button_selected'}`} onClick={() => {navigate('/portfolio')}}>Projects</button>
                        <button type="button" className={`menu_button ${window.location.pathname === "/the-prompt" && 'menu_button_selected'}`} onClick={() => {navigate('/the-prompt')}}>The Prompt</button>
                        <button type="button" className={`menu_button ${window.location.pathname === "/speaking" && 'menu_button_selected'}`} onClick={() => {navigate('/speaking')}}>Speaking & Engagement</button>
                        {/* <button type="button" className={`menu_button ${window.location.pathname === "/chat" && 'menu_button_selected'}`} onClick={() => {navigate('/chat')}}>Our Chatbot</button> */}
                        <button type="button" className={`menu_button ${window.location.pathname === "/customgpt" && 'menu_button_selected'}`} onClick={() => {navigate('/customgpt')}}>GPT Showcase</button>
                        {/* <button type="button" className={`menu_button ${window.location.pathname === "/chat" && 'menu_button_selected'}`} onClick={() => {navigate('/webapi-gallery')}}>WebAPI Gallery</button> */}
                        <button type="button" className={`menu_button ${window.location.pathname === "/work-with-us" && 'menu_button_selected'}`} onClick={() => {navigate('/work-with-us')}}>Jobs at AI Core</button>
                        <button type="button" className={`menu_button ${window.location.pathname === "/wildcat-welcome" && 'menu_button_selected'}`} onClick={() => {navigate('/wildcat-welcome')}}>Wildcat Welcome</button>
                        <button type="button" className={`menu_button ${window.location.pathname === "/webcam" && 'menu_button_selected'}`} onClick={() => {navigate('/webcam')}}>Webcam GPT</button>
                    </div>
                </div>
            </div></> :
            <div className="grey_banner">
                <div className="grey_banner_container">
                { window.location.pathname === "/chat" ? 
                    <button type="button" onClick={toggleDropdownModel} className="topbar_button_model">
                        <div className="gpt_title"><span style={{ fontFamily: "ProximaNova-SemiBold", opacity: "0.7", color: "var(--chat000)" }}>Model:</span> {GPT}</div>
                        <i className="material-symbols-outlined inline-icon" style={{ marginLeft: "8px", paddingTop: "8px", opacity: "0.7", color: "var(--chat000)" }}>expand_more</i>
                    </button>
                : ''
                }
                    <div>
                        <button type="button" className="topbar_button_outlined" onClick={() => {window.location.href = '/'}}><i className="material-symbols-outlined inline-icon" style={{ color: 'var(--chat000)' }}>home</i></button>
                        <button type="button" className="topbar_button_outlined" style={{ marginRight: "17px" }} onClick={() => {handlePopup("Share", "https://aicore.arizona.edu/chat")}}><i className="material-symbols-outlined inline-icon" style={{ color: 'var(--chat000)' }}>ios_share</i></button>
                    </div>
                </div>
            </div>}
        </header>
        </>
  )
}

export default Topbar;
